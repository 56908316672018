.App {
  text-align: center;
  background-color: #fff;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

header h1 {
  color: #ed1c24;
}

main {
  padding: 20px;
  flex: 1;
}

section {
  margin: 20px 0;
}

.service-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
}

.service-icon {
  font-size: 2rem;
  color: #ed1c24;
  margin-bottom: 10px;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
}